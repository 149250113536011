import React from 'react'
import Layout from '../components/layout/Layout/Layout'
import { graphql } from 'gatsby'
import HeroBanner from '../components/HeroBanner/HeroBanner'
import Details from '../components/Details/Details'

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query ($slug: String!) {
    datoCmsPointOfInterest(slug: {eq: $slug}) {
      title
      id
      headerImages {
        fluid(maxHeight: 450, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "768" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      headerImagesTablet: headerImages {
        title
        fluid(maxHeight: 450, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "1023" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      headerImagesDesktop: headerImages {
        title
        fluid(maxHeight: 450, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "1215" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      headerImagesWidescreen: headerImages {
        title
        fluid(maxHeight: 450, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "1920" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      fallbackMapImage {
        alt
        title
        fluid(maxWidth: 660, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "660", h: "560" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      fallbackMapImageMobile: fallbackMapImage {
        fluid(maxWidth: 768 imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "730", h: "240" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      features {
        id
        description
        name
        icon {
          fixed(width: 30, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFixed
          }
          title
          alt
        }
      }
      fullDescription
      featureDetails {
        id
        featureSpecifics
        feature {
          name
          largeIcon {
            fixed(width: 50, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFixed
            }
            title
            alt
          }
        }
      }
      location {
        latitude
        longitude
      }
      seo {
        image {
          url
        }
        description
        title
        twitterCard
      }
    }
  }
`

const PointOfInterest = (props) => {
  const dataPOI = props.data.datoCmsPointOfInterest;
  // Get separate images based on breakpoint.
  // Then pass this down to eventually reach the Map component.
  let imageSources = [];
  if (dataPOI.fallbackMapImage) {
    imageSources = [
      dataPOI.fallbackMapImageMobile.fluid,
      {
        ...dataPOI.fallbackMapImage.fluid,
        media: `(min-width: 768px)`,
      },
    ]
  }

  const buildHeaderImages = (baseImages) => {
    const processedHeaderImages = []
    for (let i = 0; i < baseImages.length; i++) {
      processedHeaderImages.push({
        sources: [
          baseImages[i].fluid,
          {
            ...dataPOI.headerImagesTablet[i].fluid,
            media: `(min-width: 769px)`,
          },
          {
            ...dataPOI.headerImagesDesktop[i].fluid,
            media: `(min-width: 1024px)`,
          },
          {
            ...dataPOI.headerImagesWidescreen[i].fluid,
            media: `(min-width: 1216px)`,
          },
        ],
        title: baseImages[i].title,
        alt: baseImages[i].alt
      })
    }
    return processedHeaderImages;
  }

  return (
    <Layout
        metaTitle={dataPOI.seo ? dataPOI.seo.title : dataPOI.title}
        metaDescription={dataPOI.seo ? dataPOI.seo.description : null}
        metaImage={
          dataPOI.seo && dataPOI.seo.image ? dataPOI.seo.image.url : null
        }
        twitterCard={
          dataPOI.seo && dataPOI.seo.twitterCard
            ? dataPOI.seo.twitterCard
            : null
        }
        pathname={props.uri}
      >
      <HeroBanner images={buildHeaderImages(dataPOI.headerImages)} />
      <Details
        leftOrRight='right'
        isBeach={true}
        title={dataPOI.title}
        description={dataPOI.fullDescription}
        features={dataPOI.features}
        featureDetails={dataPOI.featureDetails}
        location={dataPOI.location}
        fallbackImage={imageSources}
        breadcrumbUrl={props.uri}
      />
    </Layout>
  )
}

export default PointOfInterest
